body {
  background-color: black;

  margin: 0;
}


.free-app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px; 
  margin-bottom: 40px; 
}

.free-app-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.free-app-item {
  width: calc(50% - 30px);
  margin-top: 40px;
  margin-right: 14px;
  margin-left: 14px;
  position: relative;

  text-align: center;
  border-radius: 6px;
  background-color:rgba(100,100,100,0.5);
border:1px solid rgba(100,100,100,0.5);
}
@media (min-width: 600px) {
  .free-app-item {
    width: calc(33.33% - 30px);
  }
}

@media (min-width: 800px) {
  .free-app-item {
    width: calc(25% - 30px);
  }
}
@media (min-width: 1000px) {
  .free-app-item {
    width: calc(20% - 30px);
  }
}
.free-app-item img {
  object-fit: cover;
  width: 100%;
  max-width:100px;
  height: auto;
  border-radius:20%;
  margin-top:20px;
}

.free-app-item p {
  white-space: wrap;
margin-left:2px;
margin-right:2px;
  color: #ccc;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 14px;

}

.free-app-item a {
  color: #ccc;
  text-decoration: none;

}
.load-more-button {
  background-color: rgba(100, 100, 100, 0.5);
  color: #ccc; 
  padding: 10px 20px; 
  margin-top: 20px; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease; 
}


.homepage-app-list {
  height: 400px; 
  overflow-y: auto; 
  margin-top: 75px;
  margin-right: 14px;
  margin-left: 14px;
  position: relative;
border:1px solid rgba(100,100,100,0.5);
  border-radius: 6px;
    background-color:rgba(100,100,100,0.5);
}
@media (min-width: 1240px) {
  .homepage-app-list {
    width: calc(25% - 30px); 
  }
}
@media (min-width: 860px) and (max-width: 1240px) {
  .homepage-app-list {
    width: calc(33% - 30px);
  }
}
@media (min-width:540px) and (max-width: 860px) {
  .homepage-app-list {
    width: calc(50% - 30px); 
  }
}
@media (max-width: 540px) {
  .homepage-app-list {
    width: calc(100% - 30px);
  }
}
.homepage-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding:10px;
}
.homepage-app-item {
  margin: 5px;
}
.homepage-app-list a {
  white-space: wrap;
  color: #ccc;
}
.homepage-app-list ul {
  list-style-type: none;
}
.homepage-container img {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  margin-bottom:10px;
}
.homepage-app-list::-webkit-scrollbar {
  width: 2px; 
}
.homepage-app-list::-webkit-scrollbar-track {
  background-color: transparent; 
}
.homepage-app-list::-webkit-scrollbar-thumb {
  background-color: #666; 
  border-radius: 10px; 
}
.homepage-app-list h3 {
	font-size:18px;
	margin-top:0;
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
    background-color:rgba(100,100,100,0.9);
  padding: 10px 0;

}